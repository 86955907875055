@import "../../../../cmonsite-v6/_main_mixin";
@import '../../_mixin_templates';

.template-panier-1{
    #panier{
        display: none;
    }
    position: fixed;
    z-index: 99999;
    bottom: 0;
    right: 30px;
    &.template-panier #panier #paniercontent{
        background:transparent;
    }
    .ajout_panier{
        a{
            display: inline-block;
            margin-top:0;
            margin-bottom:0;
        }
    }
    .suppr{
        text-align: center;
    }
    #paniercontent{
        padding:5px;
    }
    #paniertable{
        max-height:500px;
        overflow-y: auto;
        width: 100%;
    }
    #header-panier{
        border-bottom: 1px solid #ddd;
    }
    .panier_image, .panier_prod_content, .panier_prix_del, .panier_quantite{
        padding:5px 0;
    }

    .panier_image{
        width:22%;
        img{
            max-width: 40px;
            max-height: 40px;
            height:auto;
            width:auto;
        }
    }
    .panier_prod_content{
        width:50%;
        font-weight:bold;
        .panier_description{
            display: none;
        }
        .panier_attribut{
            font-weight:normal;
        }
    }
    .panier_prix_del{
        width:10%;
        .prix_panier,.prix_panier_base{
            display: none;
        }
    }
    .panier_quantite{
        width:18%;
        .text-quantite{
            font-size: 0;
            > span{
                font-size:12px;
            }
        }
    }
    .produit_panier{
        clear:both;
        display: block;
        font-size: 0;
        > div {
            font-size:12px;
            display: inline-block;
            vertical-align: middle;
        }
        &::before, &::after{
            content:'';
            display: block;
            clear:both;
        }
    }
    #panier .totalpanier{
        border-top:none;
        padding-top:15px;
        padding-right:8px;
        font-size:20px;
        font-weight:bold;
        margin-bottom:20px;
        span{
            font-size:12px;
            font-weight:normal;
        }
    }
}

@media screen and (max-width:400px){
    .template-panier-1{
        max-width:80%;
        width:80%;
        right:10%;
        #panier{
            width:100%;
            right:0;
            box-sizing: border-box;
        }
    }
}