@import "../../../../cmonsite-v6/_main_mixin";
@import '../../_mixin_templates';

/* HACK Style panier boutique */
.cmonsite-panier-4 {

  #main-conteneur {
    //z-index: 1001;
  }

  #nav-secondary > ul {
    float: left;
    max-width: calc(100% - 90px);
  }
}

.template-panier-4 {
  width: 90px;
  float: right;
  height: 40px;
  line-height: 40px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  box-sizing: border-box;
  border: none;
  font-size: 0;
  z-index: 1200;

  .connexion, #panier {
    display: inline-block;
    width: 40px;
    height: 40px;
    margin-right: 10px;
    font-size: 17px;
    vertical-align: top;
    text-align: center;
    padding: 0;
  }
  .connexion p {
    margin: 0;
  }
  #panier {
    margin-right: 0;
    position: relative;
    font-family: inherit;
    box-shadow: none;
    //display: block !important; //Passer au-dessus du js dans la commande
    #header-panier {
      padding: 0;
      text-align: center;
    }
    .addbasket {
      margin: 0;
    }
  }
  #paniercontent {
    top: 100%;
    right: 0;
    position: absolute;
    border: none;
    box-shadow: 0 0 5px #999;
    min-width: 500px;
  }

}

@media #{$medium-and-down} {
  .template-panier-4 {
    font-size: 0;
    z-index: 1501;
    width: auto;
    border: none;
    #header-panier {
      font-size: 0;
      padding: 0;
      line-height: 40px;
      overflow: hidden;

      .count-live {
        position: absolute;
        top: -2px;
        bottom: auto;
        right: -2px;
        left: auto;
      }

      .panier_titre {
        font-size: 22px;
        padding: 0;
        line-height: 40px;
        height: 40px;
        width: 40px;
        text-align: center;
        margin: 0;
      }

      .paniertoggle {
        display: none;
      }
    }
  }
}

@media #{$small-and-down} {
  .template-panier-4 {
    #paniercontent {
      min-width: 350px;
    }
  }
}