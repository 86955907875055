@import "../../../../cmonsite-v6/_main_mixin";
@import '../../_mixin_templates';

/* HACK Style panier boutique */
.cmonsite-panier-2 {
    #main-conteneur {
    }
    #nav-principal > ul {
        float: left;
        max-width:calc(100% - 130px);
    }
}

.template-panier-2 {
    width: 140px;
    float: right;
    min-height: 40px;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    box-sizing: border-box;
    #panier {
        //display: block !important; //Passer au-dessus du js dans la commande
        border: none;
        bottom: auto;
        left: 0;
        top: 0;
        width: 100%;
        position: relative;
        border-radius: 0;
        height: 100%;
        padding: 0;
        box-shadow: none;
        font-family:inherit;
        .addbasket {
            margin: 0;
        }
    }
    #paniercontent{
        min-width: 500px;
    }


}



@media #{$medium-and-down} {
    .cmonsite-panier-2 {
        #nav-principal > ul{
            padding-right: 60px;
            max-width: 100%;
        }
        #nav-principal > .menu_burger {
            float: none;
            max-width:100%;
            padding-right: 60px;
            + ul{
                padding-right: 0;
            }
        }
    }
    .template-panier-2 {
        width:100%;
        height:auto;
        position:absolute;
        border:none;
        min-width: 0;
        font-size: 0;
        min-height: 0;
        top:50px;
        z-index: 1501;
        left:auto;
        right:0;
        #panier{
            background-color: inherit;
        }
        #header-panier{
            overflow: hidden;
            background-color: inherit;
            width:50px;
            height:50px;
            line-height:30px;
            position:absolute;
            right:0;
            top:-50px;
            text-align: center;
            z-index: 10;
            padding:10px;
            -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,0.1);
            -moz-box-shadow: 0 2px 5px 0 rgba(0,0,0,0.1);
            box-shadow: 0 2px 5px 0 rgba(0,0,0,0.1);
            font-size: 0;
            text-indent:-9999px;
        }
        .count-live{
            font-size: 10px;
            left:27px;
            top:12px;
            text-indent:0;
            margin:0;
        }
        .fa-shopping-cart{
            font-size: 22px;
            margin-right:0;
            text-indent:9999px;
        }
        .paniertoggle{
            font-size: 0;
            display: block;
            position: absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            margin: 0;
            padding: 0;
            .fa{
                font-size: 0;
            }
        }
        #paniercontent{
            z-index: 5;
            left: auto;
            right: 0;
            max-width: 100%;
            padding-top:0;
            min-width: 350px;
        }
    }
}