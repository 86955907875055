@import "cmonsite-v6/main_mixin";
@import 'design/variables';

#panier {
  //display: none;
  width: 300px;
  padding: 10px;
  border-radius: 3px 3px 0 0;
  font-family: arial;
  font-size: 12px;
  box-shadow: 0 0 6px -1px rgba(0, 0, 0, 0.57);

  .panier_nom a {
    color: inherit;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  .panier_quantite {
    align-items: center;

    > * {
      padding: 0 5px;
    }

    .inputqte {
      width: 56px;

      input {
        height: 30px;
        text-align: center;
      }
    }
  }

}

.template-panier {
  #panier {
    * {
      box-sizing: border-box;
    }

    .panier_image a img {
      margin: 0;
    }

    .inputqte .cart_quantity_input {
      margin-bottom: 0;
    }
  }
}

.template-panier {
  &.template-panier-1 {
    #panier {
      .produit_panier {
        .panier_quantite {
          display: inline-flex;
          flex-wrap: wrap;

          .downqte, .upqte, .down_quantity_booking, .up_quantity_booking {
            width: 50%;
            flex: 0 auto;
            margin-top: 6px;
          }

          .downqte, .down_quantity_booking {
            order: 2;
          }

          .upqte, .up_quantity_booking {
            order: 3;
          }

          .inputqte, .inputqte_booking {
            order: 1;
            width: 100%;

            input, strong {
              width: 100%;
              padding: 0 5px;
            }

            strong {
              display: block;
              text-align: center;
            }
          }

        }
      }
    }
  }

  &.template-panier-4, &.template-panier-2, &.template-panier-3 {
    #panier {
      .produit_panier {
        .panier_quantite {
          .inputqte {
            input {
              width: 100%;
              padding: 0 5px;
            }
          }

        }
      }
    }
  }
}

.template-panier {
  color: #000;
  background: #fff;
  border: 1px solid #ccc;
}

#panier .button-cart {
  color: hsl(0, 0%, 0%);
}

.template_flux_panier {

  #amazonpay_login_button {
    display: inline-block;
    float: right;
    margin-top: 18px;
    margin-bottom: 15px;
    margin-right: 15px;
    height: 59px !important;

    img {
      max-height: 59px !important;
      height: 59px !important;
    }
  }

  #infoscommande, .flux-panier-container a:not(.button):not(.addbasket):not(.zoom):not(.button):not(.addcommentaires):not(.bx-prev):not(.bx-next):not(.sous_navigation_link_p) {
    color: #000 !important;
  }

  button, a, .button {
    cursor: pointer;
  }

  &.template_flux_panier_1 {
    color: #000 !important;

    label {
      color: #000 !important;
    }

    .block-reduc {
      position: relative;

      &-deploy {
        height: 30px;
        text-align: center;
        line-height: 30px;
        padding: 0;
        margin: 0;
        cursor: pointer;
        position: absolute;
        top: 15px;
        left: 15px;
        right: 15px;
        display: none;

        &::before {
          content: "\f0d7";
          display: block;
          font-family: 'fontAwesome', sans-serif;
          font-size: 20px;
          line-height: 30px;
          width: 50px;
          text-align: center;
          float: right;
        }
      }
    }

    .resume-cart-item-pu {

      display: flex;
      flex-wrap: wrap;

      &__base {
        display: flex;
        width: 100%;
        align-items: center;
        margin-bottom: 6px;

        .base-price {
          flex: 1 1 auto;
          opacity: 0.7;
          text-decoration: line-through;
        }
      }

      &__price {

        display: flex;
        width: 100%;
        justify-content: center;
        flex-wrap: wrap;

        .unit-price {
          flex: 1 1 auto;

          &__remise {
            flex: 0 0 auto;
            background: #a4a299;
            padding: 2px;
            color: #fff;
            font-size: 14px;
            width: 40px;
          }
        }
      }
    }

    .resume-cart-item-totalttc {
      .total-price, .total-base-price {
        display: block;
      }

      .total-base-price {
        text-decoration: line-through;
      }

    }

    .resume-cart-item-quantity {
      display: flex;
      align-items: center;
      justify-content: center;

      > * {
        padding: 0 5px;
        flex: 0 auto;
      }

      .input-quantity {
        width: 56px;

        .resume_quantity_input {
          height: 30px;
          width: 100%;
          text-align: center;
        }
      }

    }

    .label-quantity {
      display: inline-block;
      margin: 5px;
      padding: 0 5px;
    }

    #resume, #finish, #infoscommande, #connexion-inscription {
      label {
        color: #000 !important;
      }
    }

    #resume, #finish {
      h4 {
        text-align: center;
        width: 85%;
        padding-bottom: 10px;
        border-bottom: 1px solid #ccc;
        font-size: 18px;
      }
    }

    .side-tabs {

      ul {
        background: #fff;
      }

      ul li {
        opacity: 0.9;
        background: #fff !important;
        color: #999 !important;
        border-bottom: 2px solid #fff !important;

        span::after, span::before, a::before, a::after, .etapes-after {
          display: none;
        }

        &.step-done {
          background: #fff !important;
          color: #fff !important;
          border-bottom: 2px solid #999 !important;
          opacity: 0.4;

          a {
            color: #000 !important;
          }
        }

        &.active {
          a, span {
            color: #000 !important;
          }

          background: #eee !important;
          opacity: 1;
          color: #000 !important;
          border-bottom: 2px solid #000 !important;

          &::after {
            content: "\f054";
            font-size: 15px;
            font-family: fontAwesome, sans-serif;
            display: block;
            line-height: 33px;
            margin-right: 15px;
            position: absolute;
            right: 0;
            top: 0;
          }
        }
      }
    }

    #connexion-inscription > .col.s6 {
      padding: 0 25px;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }

    .inscription, .connexion {
      box-sizing: border-box;
      background: #fff;
      box-shadow: 0 0 5px #999;
      margin-bottom: 0;
      height: 100%;
    }

    .side-tabs.etapes {
      width: 85%;
      padding-left: 0;
      margin: 0 auto;
      border-bottom: 1px solid #ddd;

      li > a {
        width: 100%;
      }
    }

    .block-reduction-code {

      margin-top: 10px;

      label {
        line-height: 31px;
      }

      .form-control {
        padding: 0 10px;
        box-sizing: border-box;
        width: 100%;
        margin: 0;
      }

      .add_code_reduc, .remove_code_reduc {
        &.button.btn {
          margin: 5px 10px;
          border-radius: 0;
          font-size: 12px;
          padding: 5px 10px;
          float: none;
          display: inline-block;
          width: auto;
          vertical-align: middle;
          text-decoration: none;
          font-weight: normal;
          background-color: #fff !important;
          color: #000 !important;
          border-color: #000 !important;
          transition: background-color .2s linear;

          &:hover, &:focus {
            background-color: #eee !important;
            color: #000 !important;
            border-color: #000 !important;
          }
        }
      }

      .input-group-btn input.button {
        text-align: center;
        margin: 0;
        width: 100%;
        border-radius: 0;
        border: none;
      }

      .add_code_reduc, .remove_code_reduc {
        &.button.btn {
          margin: 5px 10px;
          border-radius: 0;
          font-size: 12px;
          padding: 5px 10px;
          float: none;
          display: inline-block;
          width: auto;
          vertical-align: middle;
        }
      }
    }

    .contents-tabs {
      border: none;
      margin-bottom: 30px;
    }

    div#resume {

      h4 {
        width: 85%;
        margin: 0 auto 15px;
      }

      .block-resume-cart {
        border-bottom: 1px solid #ccc;

        .resume-cart-legend {
          padding: 10px;
          background: #eee;
          font-weight: bold;
          line-height: 30px;
          border: 1px solid #fff;

          .row {
            margin: 0;

            .col {
              text-align: center;
            }
          }
        }

        .resume-cart-products {
          background: #fff;

          .resume-cart-item-title {
            font-weight: bold;
          }

          .resume-cart-item {
            border-top: 1px solid #f8f8f8;
            border-bottom: 1px solid #f8f8f8;
            margin: 0;
            padding: 5px 10px;
            align-items: center;

            .col {
              text-align: center;

              > .row {
                align-items: center;
              }
            }

          }

          .product-title {
            font-weight: bold;
          }

          .fa-minus::before,
          .fa-plus::before {
            padding: 5px;
            color: #fff;
            background: #999;
            font-size: 10px;
          }

          .fa-trash-o::before {
            font-size: 20px;
          }
        }
      }

      .block-recap-panier {
        width: 85%;
        margin: 0 auto;
        box-shadow: 0 0 5px #999;
        background: #fff;
      }
    }

    div#information {

      #connexion-inscription {
        .alert {
          margin: 15px;
        }
      }

      .connexion-inscription-header {
        padding: 10px 15px;
        background: #eee;
        border: 1px solid #fff;

        h4 {
          margin: 0;
        }
      }

      .connexion-inscription-container {
        padding: 15px;
      }

      #connexion-inscription {
        width: 85%;
        margin: 0 auto;
      }

      .facebook-row {
        bottom: 0;
        border-top: 1px solid #ddd;
        margin-top: 10px;
      }

      #infoscommande .infoscommande-header {
        padding: 10px 15px;
        background: #eee;
        border: 1px solid #fff;
        color: #000;
      }

      #infoscommande .infoscommande-container {
        padding: 15px;
      }

      .list_address {
        padding-left: 0;
        margin-top: 0;

        > p {
          width: 100%;
          padding: 0 10px;
        }

        > li {
          padding: 10px;
          background: #eee;
          border: 1px solid #ddd;
          margin: 10px;

          &:hover {
            box-shadow: 0 0 5px #999;
            border: 1px solid #fff;
          }
        }
      }

      div.next-step {
        width: 85%;
        margin: 0 auto;
        padding: 0 !important;

        .col {
          padding: 0;
        }

        .button {
          text-transform: uppercase;
          font-weight: bold;
          padding: 15px;
          font-size: 1.2rem;

          .fa-angle-right:before {
            margin-left: 10px;
            font-size: 1.5rem;
          }
        }
      }

      #infoscommande {
        h4 {
          overflow: hidden;
          margin-bottom: 0;
        }
      }
    }

    div#finish {

      .block-info-payment {
        width: 85%;
        margin: 0 auto;
        box-sizing: border-box;
        background: #fff;
        box-shadow: 0 0 5px #999;
        padding: 0;

        > .col {
          padding: 0;
        }

        .block-resume-price {
          margin: 0;
          border-top: 1px solid #ccc;
        }
      }

      .block-reduction-code ul {
        margin: 10px 0;
      }

      div.next-step {
        width: 85%;
        margin: 0 auto;

        .col.s12 {
          margin: 0;
          padding: 0;
        }
      }

      h5 {
        padding: 10px 15px;
        background: #eee;
        border: 1px solid #fff;
        color: #000;
      }
    }

    div#resume, div#finish {

      .block-resume-price {
        width: 100%;
        display: flex;
        margin: 0 auto;
        padding: 0 !important;
        border: none;

        .col.s6 {
          background: #fff;
        }
      }

      .block-reduction {
        width: 50%;

        .block-with-background {
          padding: 20px;
          border: none;
        }

        .ajout-promo {
          padding: 10px;
          background: darkseagreen;
        }
      }

      .block-price {
        width: 50%;
        padding: 0;

        .block-with-background {
          border-left: 1px solid #ccc;
          border-top: none;
          border-right: none;
          border-bottom: none;
          padding: 0;

          .bloc-total {
            padding: 0;
            margin: 0;

            li {
              margin: 0;
              padding: 10px 20px;

              &:not(:last-child) {
                border-bottom: 1px solid #eee;
              }

              &.content-payment-global {
                background: #eee;
                padding: 20px;
                margin: 0;
              }
            }
          }
        }
      }

      div.next-step {
        width: 85%;
        margin: 0 auto;
        padding: 0 !important;

        .col {
          padding: 0;
        }

        .button {
          text-transform: uppercase;
          font-weight: bold;
          padding: 15px;
          font-size: 1.2rem;

          .fa-angle-right:before {
            margin-left: 10px;
            font-size: 1.5rem;
          }
        }
      }

      .resume-cart-item-produit {
        &__item {
          align-items: center;
        }
      }
    }

    .block-payment-cart__item {
      border: 1px solid #f8f8f8;
      margin: 0;
      align-items: center;
      padding: 10px;
      background: #fff;

      .row {
        align-items: center;
      }

      &:last-child {
        border-bottom: 1px solid #ccc;
      }
      .cart-base-price {
        text-decoration: line-through;
        opacity: 0.7;
      }
    }

    .block-payment-cart__item-info {
      .cart-price {
        flex: 1 1 auto;
        text-align: right;
        padding-right: 15px;
      }

      .cart-quantity {
        margin-left: 10px;
      }
    }

    .message-commande {
      width: 85%;
      background: #fff;
      box-shadow: 0 0 5px #999;
      margin: 20px auto;

      h4 {
        padding: 10px;
        background: #eee;
        margin: 0;
      }

      .message-commande-container {
        padding: 15px;
      }
    }
  }

  .msg-shipping {
    font-size: 13px;
    padding: 3px 9px;
    display: inline-block;
    font-weight: 500;

    &.msg-shipping__in-stock {
      background-color: #dff0d8;
      border-color: #d6e9c6;
      color: #3c763d;
    }

    &.msg-shipping__out-of-stock {
      border: 1px solid #faebcc;
      background-color: #fcf8e3;
      color: #8a6d3b;
    }
  }

}

.flux-panier-msg-perso {
  margin: 10px 0;
}

.error_panier {
  padding: 10px;
  background: red;
  color: #fff;
  width: 100%;
  display: block;
}

#resume table {
  table-layout: auto;
}

#panier table {
  margin: 0 0 20px;
  border-collapse: collapse;
  text-align: left;
  font-size: 1em;
  background: none;
  border: none;
  width: 100%;
  color: #222;
}

$fa-var-caret-down: "f0d7";
$fa-var-caret-up: "f0d8";
$fa-var-caret-square-o-down: "f150";
$fa-var-caret-square-o-up: "f151";

.paniertoggle {
  .fa {
    float: right;
    font-size: 16px;
  }

  &, &.up {
    i:before {
      @include char($fa-var-caret-square-o-down);
    }

  }

  &.down {
    i:before {
      @include char($fa-var-caret-square-o-up);
    }
  }
}

#panier a.paniercommander {
  border: 1px solid rgb(0, 0, 0);
  border-radius: 3px;
  display: inline-block;
  margin-top: 14px;
  padding: 12px 0;
  text-align: center;
  text-decoration: none;
  width: 100%;
  z-index: 100;
}

.button-cart {
  border: 2px solid rgb(0, 0, 0);
  border-radius: 3px;
  display: inline-block;
  padding: 10px 20px;
  text-decoration: none;
  font-weight: bold;
  color: black;
}

#panier .fa-shopping-cart {
  color: inherit;
  font-size: 18px;
  margin-right: 10px;
  vertical-align: middle;
}

#panier .produit_panier {
  border-bottom: 1px solid #ddd;
}

#panier tr {
  background: none;
}

#panier th {
  border-bottom: 1px solid #ccc;
  border-top: 2px solid #ccc;
}

#header-panier {
  color: #222;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0;
  padding: 4px 5px 10px;
  text-align: left;
}

#panier h4 {
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
  color: #000;
}

#panier, #paniercmd {
  .picto-panier.fa.fa-plus:hover {
    color: #189e16;
  }

  .picto-panier.fa.fa-minus:hover {
    color: red;
  }

  .picto-panier.fa.fa-times:hover {
    color: #ff0000;
  }
}

#panier tr#totalpanier {
  position: absolute;
  bottom: 53px;
  border: none;
}

#panier tr#totalpanier td {
  border: none;
}

#paniercmd {
  font-family: arial;
  font-size: 12px;
  zoom: 1; /* IE < 8 */
}

#paniercmd table {
  width: 100%;
  margin: 0;
  border-collapse: collapse;
  text-align: left;
  font-size: 1em;
}

#paniercmd table th {
  text-align: center;
}

#paniercmd table td {
  text-align: center;
}

#paniercmd h4 {
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
  margin: 16px 0;
}

#totalbloc {
  float: right;
  padding: 10px 20px 5px;
  border: 1px solid #ccc;
  border-top: none;
  background: #eee;
  width: 290px;
  color: #000000;
}

#totalfraisdeport > span,
#totalpaniercommande > span,
#totalpaniercommandefraisdeport > span,
#totalfraisdeport > strong,
#totalpaniercommande > strong,
#totalpaniercommandefraisdeport > strong {
  float: right;
  white-space: nowrap;
}

#totalpaniercommandefraisdeport {
  margin-bottom: 5px;
  font-weight: 700;
}

#paniercmd .libprod {
  text-align: left;
}

#paniervidefull {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #fff;
}

#infoscommande {
  margin: 15px auto;
  width: 85%;
  background: #fff;
  box-shadow: 0 0 5px #999;
}

#infoscommande input[type=text] {
  margin-bottom: 10px;
  padding: 5px;
  border: 1px solid #ccc;
}

#infoscommande textarea {
  margin-bottom: 10px;
  padding: 5px;
  border: 1px solid #ccc;
}

#infoscommande fieldset {
  // width: 500px;
  padding-left: 60px;
  border: none;
}

.displaycmd {
  text-align: center;
  font-family: arial;
}

.displaycmd h4 {
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
  text-align: center;
}

p.attr select {
  width: 140px;
}

#panier th,
#panier td {
  font-size: 12px;
}

#panier .totalpanier {
  text-align: right;
}

#panier th,
#panier td,
#paniercmd th,
#paniercmd td {
  padding: 5px 0;
}

#panier .totalpanier > td {
  text-align: right;
}

.ajout_panier {

  position: relative;
  text-align: center;
  margin: 12px;
}

#panier #notificationsLoader,
#paniercmd #notificationsLoader {
  position: absolute;
  top: 12px;
  right: 10px;
}

#panier td.suppr,
#panier .upqte,
#panier .downqte,
#paniercmd td.suppr {
  cursor: pointer;
}

#paniercmd a {
  color: #444;
}

#panier .paniertoggle {
  color: inherit !important;
}

#panier .totalpanier {
  border-top: 1px solid #CCCCCC;
}

#paiementchoix,
#confirmation {
  clear: both;
}

#paiement_paypal {
  margin-left: 15px;
}

#totalpaniercommande, #totalfraisdeport, #totalpaniercommandefraisdeport {
  border-bottom: 1px solid #CCCCCC;
  margin-bottom: 1px;
  padding: 12px 0;
}

#totalpaniercommandefraisdeport > strong, #totalpaniercommande > strong {
  font-size: 13px;
  margin: 0;
  min-width: 35px;
  text-align: right;
}

#totalpaniercommandefraisdeport {
  border: none;
}

input.submitpaiement,
a.submitpaiement {
  margin-left: 5px;
  width: auto;
  display: inline;
}

input.submitpaiement:hover,
a.submitpaiement:hover {
  color: #FFF;
  background: #444;
}

#bloc-codereduc:before,
#paiementchoix:before,
#paniercmd:before,
#bloc-codereduc:after,
#paiementchoix:after,
#paniercmd:after {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden;
}

#bloc-codereduc:after,
#paniercmd:after,
#paiementchoix:after {
  clear: both;
}

#paiementchoix {
  zoom: 1; /* IE < 8 */
  float: left;
  width: 100%;
  margin-bottom: 15px;
}

#bloc-codereduc {
  float: left;
  zoom: 1; /* IE < 8 */
  background: none repeat scroll 0 0 #fafafa;
  border-bottom: 1px solid #CCCCCC;
  padding: 15px 0 15px 15px;
  width: 393px;
}

#label-code {
  background: none repeat scroll 0 0 #FFFFFF;
  float: left;
  line-height: 28px;
  margin: -10px 0 15px -20px;
  padding: 10px 0 10px 20px;
  width: 310px;
  border-bottom: 1px solid #CCCCCC;
}

#code_reduc {
  font-size: 15px;
  font-weight: bold;
  height: 20px;
  vertical-align: middle;
  width: 100px;
  padding: 2px;
  margin: 0;
}

#submit_code, #submit_supprimer_code {
  display: inline;
  float: none;
  margin: 0;
  padding: 4px 0;
  width: 46px;
}

#code_reponse {
  display: block;
  float: left;
  margin: 15px;
  padding: 5px;
  width: 360px;
}

#code_reponse.success {
  background: none repeat scroll 0 0 #FFFFFF;
  border: 1px solid green;
  color: green;
}

#code_reponse.alert {
  color: red;
  border: 1px solid red;
  background: none repeat scroll 0 0 #FFFFFF;
}

.input-panier:before,
.input-panier:after {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden;
}

.input-panier:after {
  clear: both;
}

.input-panier {
  zoom: 1; /* IE < 8 */
}

.input-panier.double label {
  float: left;
  width: 50%;
}

.input-panier.alone label {
  float: left;
  width: 100%;
}

.input-panier input, .input-panier textarea {
  display: block;
  margin: 5px 0 0;
  width: 84%;
}

.input-panier.alone input, .input-panier.alone textarea {
  width: 92%;
}

#totalbloc .picto-panier {
  font-size: 16px;
  margin-right: 5px;
}

#paniercommandecontent table .picto-panier, #paniertable .picto-panier, #paniercmd .picto-panier {
  font-size: 14px;
  cursor: pointer;
}

.paiements .paiement-carte-bancaire {
  background: url("../images/cb.png") no-repeat scroll 0 0 transparent;
  display: block;
  height: 35px;
  margin: 0 auto;
  width: 140px;
}

.paiements .paiement-carte-bancaire {
  background: url("../images/cb_amex.png") no-repeat scroll 0 0 transparent;
  display: block;
  height: 35px;
  margin: 0 auto;
  width: 180px;
}

.paiements {
  font-size: 1.2em;
  font-weight: bold;
  line-height: 7.5em;
  vertical-align: bottom;
  text-align: center;

}

.paiements img {
  margin-top: 10px;
}

.paiements label {
  display: block;
  float: left;
  width: 200px;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.7em;
}

.paiements label {
  border-radius: 5px 5px 5px 5px;
  cursor: pointer;
  display: inline-block;
  margin: 0 10px 0 -2px;
  padding: 10px 0;
  text-align: center;
  width: 190px;
  float: none;

  > span {
    display: block;
  }
}

#paiementchoix .selected strong {
  color: #000;
}

.paiements .paiement-paypal {
  background: url("../images/design/paiement/logo_paypal.png") no-repeat scroll 0 0 transparent;
  display: block;
  height: 35px;
  margin: 0 auto;
  width: 68px;
}

.paiements .paiement-hipay {
  background: url("../images/hipay.png") no-repeat scroll 0 0 transparent;
  display: block;
  height: 35px;
  margin: 0 auto;
}

.paiements .paiement-cheque {
  background: url("../images/cheque.png") no-repeat scroll 0 0 transparent;
  display: block;
  height: 35px;
  margin: 0 auto;
  width: 85px;
}

.paiements .paiement-especes {
  background: url("../images/especes.png") no-repeat scroll 0 0 transparent;
  display: block;
  height: 35px;
  margin: 0 auto;
  width: 85px;
}

.paiements .paiement-cheque-ancv {
  background: url("../images/cheque-ancv.jpg") no-repeat scroll 0 0 transparent;
  display: block;
  height: 35px;
  margin: 0 auto;
  width: 85px;
}

.paiements .paiement-livraison {
  background: url(../images/icone_paiement-livraison_front.png) no-repeat scroll 0 0 transparent;
  width: 65px;
  display: block;
  height: 35px;
  margin: 0 auto;
}

.paiements .paiement-livraison {
  background: url(../images/western-union/logo.wu.big.svg) no-repeat center;
  width: 100%;
  height: 35px;
  display: block;
  margin: 0 auto;
}

.paiements .paiement-virement {
  background: url("../images/virement-bancaire.png") no-repeat scroll 0 0 transparent;
  display: block;
  height: 35px;
  margin: 0 auto;
  width: 85px;
}

.block-code-reduction {
  label {
    padding-top: 7px;
    margin-bottom: 0;
    text-align: right;
    width: 100%;
  }
}

.input-group {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  display: table;
  border-collapse: separate;

  .form-control {
    height: 31px;
    border: 1px solid #ccc;
    position: relative;
    z-index: 2;
    float: left;
    width: 100%;
  }

  .form-control, .input-group-addon, .input-group-btn {
    display: table-cell;
  }

  .input-group-btn .button {
    margin: 0;
  }

  &:last-child > .button {
    z-index: 2;
    margin-left: -1px;
  }

  .button {
    position: relative;
    margin-top: 0;
    height: 31px;
    line-height: 18px;
    margin-bottom: 0;
    padding: 0 6px;
    width: auto;
  }
}

.input-group-addon, .input-group-btn {
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
}

/*****   PROCESSUS ENVOIE MOINS CHER *****/
#resume, #information, #finish {
  .contents-tabs .button {
    display: inline-block;
    padding: 5px 11px;
    font-size: 16px;
  }
}

#resume, #finish {
  .bloc-total-loader, .block-loader-carriers {
    font-size: 100px;
    text-align: center;
  }

  .block-with-background {
    border: 1px solid #ccc;
    border-radius: 0;
    background-color: rgba(255, 255, 255, 0.53);
    padding: 10px;
  }

  .bloc-total {
    padding: 0 15px;

    li {
      margin-bottom: 7px;
      list-style: none;
      font-size: 15px;

      > span:last-child {
        float: right;
      }
    }

    li.content-payment-global {
      font-size: 20px;
      font-weight: bold;
      margin-top: 20px;
    }
  }

  #code_reponse {
    max-width: 90%;
    margin: 15px auto;
  }

  ul {
    padding-left: 0;
  }

  li {
    list-style: none;
  }
}

.form-order {
  a.next-step {
    float: right;
    display: inline-block;
  }
}

#information {
  select {
    max-width: 100%;
  }
}

#information #infoscommande {
  select {
    margin: 3px 0 10px;
    padding: 4px;
    border: 1px solid #ccc;
    display: block;
  }

  .pull-right {
    font-size: .9rem;
    font-weight: normal;
    text-align: right;
    margin-top: 5px;
    margin-bottom: 30px;
    width: auto;

    a {
      display: block;
    }
  }

  > .row .col.s3 {
    float: right;
  }

  .button {
    margin-left: 0;
    max-width: 100%;
    box-sizing: border-box;
  }
}

.billing_address {
  clear: both;
}

#finish {
  h5, .h5 {
    border-bottom: 1px solid #ccc;
    font-size: 16px;
    padding: 10px;
    margin: 0;
    background: #444;
    color: #fff;
  }

  ul {
    margin: 0 10px;
  }

  li {
    list-style: none;
    margin: 5px;
  }

  .info-complementaire-form {
    padding: 15px 25px;

    input[type="text"], textarea {
      padding: 5px 10px;
    }

    select {
      padding: 5px 10px;
      width: auto;
    }

    [data-type="datedouble"] {
      input[type="text"] {
        width: auto;
        margin: 3px 5px;
      }
    }

    .qs_form {
      margin-bottom: 10px;
    }
  }

  .cart-title {
    font-weight: bold;
  }

  .row:nth-child(3) {
    label {
      display: block;
    }
  }

  .block-cgv {
    padding: 15px;
    display: block;
    width: 100%;
    border-top: 1px solid #ccc;
  }

  .button[type="submit"] {
    float: right;
    display: inline-block;
  }

  img {
    max-width: 100%;
    height: auto !important;
    max-height: 80px;
  }

  .group-selected {
    > li {
      opacity: .4;

      &:hover {
        opacity: .8;
      }
    }
  }

  .content-carriers {

    padding: 0 15px;

    &.content-carriers-none {
      margin: 15px 0;
    }

    li {
      margin: 15px 0;
      //overflow: hidden;
      &.selected {
        label {
          font-weight: bold;
        }

        .information-relay {
          border-top: 1px dashed #ccc;
          color: #000;
        }
      }
    }

    input {
      float: left;
      margin-right: 10px;
      margin-top: 15px;
      //display: table-cell;
      vertical-align: middle;
      display: none;
    }

    .line_carrier {
      &.selected {
        label {
          * {
            color: #000 !important;
          }
        }
      }
    }

    .logo-carrier {
      width: 100%;
      height: 40px;
      background-repeat: no-repeat;
      background-position: center 0;
      background-size: contain !important;
      text-align: center;
    }

    .logo-carrier-none {
      width: 110px;
      height: 40px;
      float: left;
      text-align: center;

      i {
        font-size: 24px;
        padding-top: 5px;
      }
    }

    label {
      padding: 6px 5px;
      border-radius: 5px;
      display: table;
      overflow: hidden;
      width: 100%;

      > div {
        display: table-cell;
        vertical-align: middle;

        span {
        }
      }
    }

    li label.selected {
      border: 1px solid #ccc;
    }

    label.selected span {
      font-weight: bold;
    }

    span {
      /*            margin-top: 8px;*/
      display: inline-block;
      /*            padding-left: 40px;*/
      box-sizing: border-box;
      /*text-align: center;*/
      /*display: table-cell;*/
      vertical-align: middle;
    }
  }

  .information-relay {
    padding-top: 7px;
    padding-left: 26%;

    p {
      margin-top: 7px;
    }

    span {
      display: inline-block;

      &::first-letter {
        text-transform: uppercase;
      }
    }
  }

  #paiementchoix {
    ul {
      padding-left: 0;
      text-align: center;
    }

    li {
      display: inline-block;
      margin: 10px 10px 0 0;
    }

    label {
      display: block;
      overflow: hidden;
      padding: 6px 5px;
      width: 185px;
      box-sizing: content-box;
      border-radius: 0;
    }

    input {
      display: none;
      margin: 0 auto 5px;
    }

    li span:last-child {
      margin-top: 8px;
      display: inline-block;
      box-sizing: border-box;
      text-align: center;
      width: 100%;
    }

    strong {
      font-weight: normal;
      font-size: 15px;
    }

    label.selected strong {
      font-weight: bold;
    }

    .paiement-virement, .paiement-cheque, .paiement-hipay, .paiement-paypal, .paiement-carte-bancaire, .paiement-carte-bancaire-amex,
    .paiement-especes, .paiement-cheque-ancv, .paiement-apple-pay, .paiement-google-pay, .paiement-livraison, .paiement-stripe, .paiement-westernunion,
    .paiement-bancontact, .paiement-sofort, .paiement-giropay, .paiement-alma2, .paiement-alma3, .paiement-alma4 {
      height: 35px;
      display: block;
      margin: 0 auto;
    }

    .paiement-virement {
      background: url("../images/virement-bancaire.png") no-repeat scroll 0 0 transparent;
      width: 85px;
    }

    .paiement-cheque {
      background: url("../images/cheque.png") no-repeat scroll -10px 0 transparent;
      width: 64px;
    }

    .paiement-especes {
      background: url("../images/icone_espece-front.png") no-repeat scroll -10px 0 transparent;
      width: 64px;
    }

    .paiement-cheque-ancv {
      background: url("../images/cheque-ancv.jpg") no-repeat scroll 0 0 transparent;
      width: 102px;
    }

    .paiement-livraison {
      background: url(../images/icone_paiement-livraison_front.png) no-repeat scroll 0 0 transparent;
      width: 65px;
    }

    .paiement-westernunion {
      background: url(../images/western-union/logo.wu.big.svg) no-repeat center;
      width: 100%;
    }

    .paiement-hipay {
      background: url("../images/hipay.png") no-repeat scroll -122px 0 transparent;
      width: 62px;
    }

    .paiement-paypal {
      background: url("../images/design/paiement/logo_paypal.png") no-repeat scroll 0 transparent;
      width: 106px;
    }

    .paiement-carte-bancaire {
      background: url("../images/cb.png") no-repeat scroll -7px 0 transparent;
      width: 114px;
      max-width: 118px;
    }

    .paiement-stripe {
      background: url("../images/cb_amex.png") no-repeat scroll -7px 0 transparent;
      width: 175px;
      max-width: 175px;
    }

    .paiement-alma2, .paiement-alma3, .paiement-alma4 {
      background: url("../images/cb_amex.png") no-repeat scroll -7px 0 transparent;
      width: 175px;
      max-width: 175px;
    }

    .paiement-carte-bancaire-amex {
      background: url("../images/cb_amex_only.png") no-repeat scroll 0 0 transparent;
      width: 55px;
    }

    .paiement-apple-pay {
      background: url("/images/design/paiement/apple-pay.png") no-repeat scroll 0 0 transparent;
      width: 114px;
      background-position-x: -35px;
    }

    .paiement-google-pay {
      background: url("/images/design/paiement/google-pay.png") no-repeat scroll 0 0 transparent;
      width: 114px;
      background-position-x: -35px;
    }

    .paiement-sofort {
      background: url("/images/design/paiement/logo_sofort.png") no-repeat scroll 0 0 transparent;
      width: 52px;
    }

    .paiement-bancontact {
      background: url("/images/design/paiement/logo_bancontact.png") no-repeat scroll 0 0 transparent;
      width: 50px;
    }

    .paiement-giropay {
      background: url("/images/design/paiement/logo_giropay.png") no-repeat scroll 0 0 transparent;
      width: 82px;
    }

    li .block-paiement-cb-paypal {
      height: 35px;
      display: block;

      .paiement-carte-bancaire {
        display: inline-block;
      }

      .paiement-paypal {
        display: inline-block;
        margin-left: 5px;
      }

      span.paiement-paypal {
        width: 106px;
        margin-top: 0;
      }
    }

  }

  #paiementchoix, .content-carriers {
    ul > li {
      background: #fff;
      border: 1px solid #ccc;
      color: #000;
      transition: opacity .2s linear, border-color .2s linear, background .2s linear;

      label {
        cursor: pointer;
      }

      &:hover, &.selected {
        border-color: #545454;
        background: #f8f8f8;
        opacity: 1;
      }

      &.selected {
        position: relative;

        &:before {
          @include char("f00c");
          font-family: FontAwesome;
          position: absolute;
          left: -7px;
          top: -7px;
          font-size: 1.4rem;
          color: #000;
          border: 1px solid #545454;
          border-radius: 50%;
          padding: 2px;
          background: #fff;
          z-index: 5;
        }
      }
    }
  }
}

.list-product-attribute {
  font-weight: normal;
  font-size: 12px;
}

.carrier_price {
  text-align: right;

  span {
    display: inline-block;
    padding-right: 20px;
  }
}

.form-group input[type=text], .form-group textarea {
  box-sizing: border-box;
}

@media screen and (max-width: 1024px) {
  .template_flux_panier {

    &.template_flux_panier_1 {
      .side-tabs.etapes,
      div#resume .block-recap-panier,
      div#resume div.next-step,
      div#information #connexion-inscription,
      div#information div.next-step,
      #infoscommande,
      div#finish .block-info-payment,
      div#finish div.next-step,
      div#resume h4,
      div#finish h4,
      .message-commande {
        width: 100%;
      }

      h4 {
        margin: 0;
      }

      #information #infoscommande .pull-right {
        /*Hack pour passer au dessus du important style all design, pour assurer un rendu propre*/
        float: none !important;
      }

      div#information .list_address {
        margin: 0 0 20px;
      }

      #connexion-inscription > .col.s6 {
        width: 100%;
        margin: 15px 0;
        padding: 0;
      }

      div#resume .block-resume-price .col.s6,
      div#finish .block-resume-price .col.s6 {
        width: 100%;
      }

      div#resume .block-price .block-with-background,
      div#finish .block-price .block-with-background {
        border-left: none;
      }

      div#resume .block-price .block-with-background .bloc-total li.content-payment-global,
      div#finish .block-price .block-with-background .bloc-total li.content-payment-global {
        font-size: 16px;
      }

      #finish .content-carriers .logo-carrier-none {
        float: none;
      }

      div#information .connexion-inscription-container > p {
        height: auto !important;
        margin-bottom: 15px !important;
      }

      .block-reduc {
        &-texte {
          padding-right: 50px;
          min-height: 30px;
          display: block;
          position: relative;
        }

        &-deploy {
          display: block;

          + ul {
            display: none;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 980px) {

  .template_flux_panier {
    #resume {
      .resume-cart-item-quantity {
        flex-wrap: wrap;

        .input-quantity {
          width: 100%;
          order: 1;

          input {
            width: 100%;
          }
        }

        .down-quantity {
          width: 50%;
          order: 2;
          margin-top: 6px;

          i:before {
            font-size: 15px !important;
          }
        }

        .up-quantity {
          width: 50%;
          order: 3;
          margin-top: 6px;

          i:before {
            font-size: 15px !important;
          }
        }
      }
    }
  }

  #resume table + div .col.s6:first-child label {
    width: 100%;
  }
  #resume table img {
    max-width: 100%;
    height: auto !important;
  }
  #resume tbody .col.s8 {
    padding-top: 15%;
  }
  #finish .content-carriers {
    .col.s3 {
      padding-left: 0;
    }

    .logo-carrier, .logo-carrier-none {
      width: 75%;
    }
  }
}

@media screen and (max-width: 680px) {
  #finish .content-carriers {
    .logo-carrier, .logo-carrier-none {
      width: 70%;
    }
  }
  .side-tabs.etapes.top a, .side-tabs.etapes.top li:nth-child(3) span {
    font-size: 13px;
  }

  .block-success-payment-products-resume {

    .col {

      &.block-success-payment-resume-product {
        order: 1;
        margin-top: 10px;
      }

      &.block-success-payment-resume {
        order: 2;
      }

    }

  }

}

@media #{$medium-and-down}{
  #finish {
    #paiementchoix {
      ul {
        display: flex;
        flex-wrap: wrap;

        > li {
          margin: 2% 2% 0 2%;
          width: 46%;
          flex: 0 0 auto;

          label {
            width: 100%;
          }
        }
      }
    }
  }
}

@media #{$small-and-down}{

  .template_flux_panier {
    #resume {
      .resume-cart-item-quantity {

        .input-quantity {
          width: 50%;
          order: 2;

          input {
            width: 100%;
          }
        }

        .down-quantity {
          width: 25%;
          order: 1;
          margin-top: 6px;

          i:before {
            font-size: 15px !important;
          }
        }

        .up-quantity {
          width: 25%;
          order: 3;
          margin-top: 6px;

          i:before {
            font-size: 15px !important;
          }
        }
      }
    }
  }

  .template_flux_panier.template_flux_panier_1 {
    div#resume {
      .resume-cart-legend {
        display: none;
      }

      .block-resume-cart .resume-cart-products .resume-cart-item {
        padding: 0;
      }
    }

    .resume-cart-products {
      .resume-cart-item-img {

      }

      [data-type] {
        display: flex;
        flex-wrap: nowrap;
        flex: 1 1 auto;
        flex-direction: row;
        border-bottom: 1px dotted rgba(0, 0, 0, .1);

        &:last-child {
          border-bottom: 1px solid rgba(0, 0, 0, .3);
        }

        &::before {
          content: attr(data-type);
          width: 50%;
          border-right: 1px dotted rgba(0, 0, 0, .1);
          //float: left;
          padding-top: 10px;
          padding-bottom: 10px;

          display: flex;
          align-items: center;
          justify-content: center;
        }

        > [class^="resume-cart-item-"] {
          padding-top: 10px;
          padding-bottom: 10px;
          width: 50%;

          display: flex;
          align-items: center;
          justify-content: center;
        }

        .resume-cart-item-pu__price {
          width: auto;
          .unit-price__remise {
            margin-left: 12px;
          }
        }

      }
    }

    .block-payment-cart__item {
      padding: 0 .5rem;

      &-image, .cart-title, .list-product-attribute {
        text-align: center;
      }

      [data-type] {
        display: flex;
        flex-wrap: nowrap;
        flex: 1 1 auto;
        flex-direction: row;
        border-bottom: 1px dotted rgba(0, 0, 0, .1);

        &:last-child {
          border-bottom: 1px solid rgba(0, 0, 0, .5);
        }

        &::before {
          content: attr(data-type);
          width: 50%;
          border-right: 1px dotted rgba(0, 0, 0, .1);
          //float: left;
          padding-top: 10px;
          padding-bottom: 10px;

          display: flex;
          align-items: center;
          justify-content: center;
        }

        > [class^="block-payment-cart__item-"] {
          padding-top: 10px;
          padding-bottom: 10px;
          width: 50%;

          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .block-success-payment-products__legend {
      display: none;
    }

    .block-success-payment-products__item, .block-success-payment-products__item__option {
      [data-type] {
        display: flex;
        flex-wrap: nowrap;
        flex: 1 1 auto;
        flex-direction: row;
        border-bottom: 1px dotted rgba(0, 0, 0, .1);

        img {
          width: 100px;
          max-width: 100%;
        }

        &:last-child {
          border-bottom: 1px solid rgba(0, 0, 0, .5);
        }

        &::before {
          content: attr(data-type);
          width: 50%;
          border-right: 1px dotted rgba(0, 0, 0, .1);
          //float: left;
          padding-top: 10px;
          padding-bottom: 10px;

          display: flex;
          align-items: center;
          justify-content: center;
        }

        > [class^="block-success-payment-products__item-"] {
          padding-top: 10px;
          padding-bottom: 10px;
          width: 50%;

          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
        }
      }
    }

    .block-success-payment-products__item__option {
      .block-success-payment-products__item-produit {
        .s3 {
          display: none;
        }
      }
    }

    .block-success-payment-total__item {
      .s6 {
        width: 50%;
      }
    }
  }

  #finish {
    #paiementchoix {
      ul {
        display: block;

        > li {
          display: block;
          margin: 10px 10px 0 10px;
          width: auto;

          label {
            width: 100%;
          }
        }
      }
    }
  }

}

@media screen and (max-width: 600px) {
  .side-tabs.etapes li {
    width: 100%;
    margin-bottom: 5px;
  }
  #resume tbody .col.s8 {
    padding-top: 0px;
    padding-bottom: 8px;
  }
  #resume .col.s3, #information .col.s3 {
    width: 100%;
  }
  #resume table + div .col.s6:first-child label {
    width: auto;
  }
  #information select {
    display: block;
  }
  #information #infoscommande .pull-right {
    text-align: left;
    margin-top: 5px;
  }
  #information .col.s3 .button {
    float: right;
  }
  #finish .row:nth-child(1) .col.s12:nth-child(2) .col.s9 {
    width: 75%;
  }
  #finish .bloc-total {
    text-align: center;
  }
  #finish .bloc-total .box {
    margin: 15px 0;

    .col.s4 {
      margin-bottom: 5px;
    }
  }
  #finish .content-payment-global span {
    margin-top: 0;
  }
  #finish .row:nth-child(3) .button {
    float: right;
    margin-bottom: 25px;
  }
  #finish .content-carriers {
    text-align: center;

    label > div {
      display: block;
    }

    .col.s3 {
      width: 100%;
    }

    .logo-carrier, .logo-carrier-none {
      width: 110px;
      margin: 0 auto;
      float: none;
    }
  }
  #finish .information-relay {
    padding-left: 0;
  }
}

@media screen and (max-width: 500px) {
  #resume table {
    width: 100% !important;
  }
  #resume table thead {
    display: none;
  }
  #resume tbody tr {
    display: block;
    padding: 8px 0;
  }
  #resume tbody td {
    display: block;
    width: 100%;
  }
  #resume table img {
    margin: 2px auto 5px;
  }
  #resume table + div .col.s6:first-child label {
    width: 100%;
  }
  #resume .panier-entete {
    font-weight: bold;
  }
  #finish {
    .row:nth-child(1) .col.s12:nth-child(2) {
      .col.s12 {
        text-align: center;
      }

      .col.s3 {
        width: 100%;
        margin-bottom: 5px;
      }

      .col.s9 {
        width: 100%;
        margin-bottom: 7px;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  #finish .row .col.s6 .col.s12 .col.s12 {
    text-align: center;
  }
  #finish .col.s6 .col.s12 .col.s3 {
    width: 100%;
  }
  #finish .col.s6 .col.s12 .col.s9 {
    width: 100%;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 360px) {
  #finish .content-carriers ul {
    padding: 0;
  }
}

#paniervide {
  padding: 10px;
}

/* Contenu des templates panier */
.template-panier-2,
.template-panier-3,
.template-panier-4 {
  box-sizing: border-box;

  * {
    box-sizing: inherit;
  }

  #paniercommande {
    text-align: right;
    background-color: rgba(0, 0, 0, .05);
    padding: 10px 15px;

    .totalpanier {
      border: none;
      font-size: 1.4rem;
      font-weight: bold;
      margin-bottom: 15px;

      .text-total {
        font-size: .9rem;
      }
    }

    .ajout_panier {
      border: medium none;
      position: relative;
      top: 0;
      margin: 10px 0 0;
      text-align: right;

      a {
        margin: 0;
        display: inline-block;
        width: auto;
        padding: 5px 10px;
        box-sizing: border-box;
      }
    }
  }

  #panier .produit_panier {
    border-bottom: 1px solid rgba(135, 135, 135, 0.3);
  }

  .produit_panier {
    clear: both;
    padding: 10px 15px 40px;
    position: relative;

    &::before, &::after {
      content: '';
      display: block;
      clear: both;
    }

    .panier_image {
      width: 20%;
      float: left;

      img {
        width: 100%;
        height: auto;
        border: 1px solid rgba(0, 0, 0, .1);
      }
    }

    .panier_prod_content {
      width: 60%;
      float: left;
      display: block;
      padding: 0 10px;
      overflow: hidden;
      text-align: left;
    }

    .panier_nom {
      font-weight: bold;
      font-size: 1.3rem;
      display: block;
      text-align: left;
      //text-transform: uppercase;
    }

    .panier_description {
      font-size: .7rem;
      font-weight: normal;
    }

    .panier_attribut {
      margin-top: 15px;
      display: block;
      text-align: left;
    }

    .prix_panier, .prix_panier_base, .panier_quantite {
      display: block;
      text-align: left;
      text-transform: uppercase;
      font-size: 1rem;
      font-weight: normal;
    }

    .prix_panier {
      font-weight: bold;
    }

    .panier_prix_del {
      width: 20%;
      float: right;
      text-align: right;

      .prix_panier, .prix_panier_base {
        text-align: right;
      }

      .prix_panier_base {
        text-decoration: line-through;
        font-size: 0.9rem;
        opacity: 0.7;
      }

      .suppr {
        margin-top: 15px;
      }
    }

    .panier_quantite {
      text-align: right;
      width: 100%;
      padding: 0;
      position: absolute;
      right: 15px;
      bottom: 0;
      padding-left: 30px;

      .text-alert-quantite {
        text-align: left;
        text-transform: none;
        font-size: 14px;
      }
    }
  }

  #paniertable {
    .suppr .picto-panier {
      font-size: 20px;
      padding: 0;
      width: 40px;
      height: 40px;
      text-align: center;
      line-height: 38px;
      opacity: .5;
      border: 1px solid rgba(0, 0, 0, .1);
      transition: opacity .2s linear, color .2s linear, border-color .2s linear;

      &:hover {
        border-color: red;
        color: red;
        opacity: 1;
      }
    }
  }

  #header-panier {
    padding: 10px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }

  #paniercontent {
    padding: 0;
    background: #fff;
    border: 1px solid;
    width: auto;
    display: none;
    right: -1px;
    top: 100%;
    position: absolute;
    z-index: 10;
    .produit_panier {
      padding-bottom: 10px;
      line-height: normal;
      display: flex;
      flex-wrap: wrap;

      .panier_image {
        order: 1;
        width: 20%;
      }

      .panier_quantite {
        width: 100%;
        order: 4;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 0;
        margin-top: 3px;

        .text-alert-quantite {
          width: 60%;
        }

        .text-quantite {
          width: 40%;
          flex: auto;
        }
      }

      .panier_prod_content {
        order: 2;
        width: 60%;
      }

      .panier_prix_del {
        width: 20%;
        order: 3;
      }

    }
  }

  #paniertable {
    color: #000;
    max-height: 300px;
    overflow-y: auto;
    width: 100%;
    margin: 0;

    .totalpanier {
      display: none !important;
    }
  }

  .total-live {
    display: none !important;
  }

  $fa-var-caret-down: "f0d7";
  $fa-var-caret-up: "f0d8";
  $fa-var-caret-square-o-down: "f150";
  $fa-var-caret-square-o-up: "f151";

  .paniertoggle {

    float: right;
    height: auto;
    width: auto;
    padding: 0;

    .fa {
      float: right;
      font-size: 16px;
    }

    &, &.up {
      i:before {
        @include char($fa-var-caret-down);
      }

    }

    &.down {
      i:before {
        @include char($fa-var-caret-up);
      }
    }
  }

  .count-live {
    background: none repeat scroll 0 0 red;
    border-radius: 50%;
    color: #fff;
    font-size: 10px;
    height: 0;
    left: 20px;
    line-height: 0;
    padding: 7px 0;
    position: absolute;
    text-align: center;
    top: 20px;
    width: 13px;
  }

}

/* Page après paiement */
.block-success-payment {
  .h5 {
    font-size: 16px;
    padding: 10px 15px;
    margin: 0;
    background: #eee;
    border-bottom: 2px solid #fff;
    color: #000;
  }

  .block-success-payment-products {
    color: #000;
    border-bottom: 1px solid #eee;

    &__legend {
      padding: 10px;
      background: #eee;
      font-weight: bold;
      line-height: 30px;
      border: 1px solid #eee;

    }

    &__item {
      border: 1px solid #eee;
      background: #fff;
      padding-top: 5px;
      padding-bottom: 5px;

      &__option {
        min-height: 50px;
        border: 1px solid #eee;
        padding-top: 5px;
        background: #fff;

        > .row {
          align-items: center;
          margin: 0;

          .col:first-child {
            min-height: 50px;
          }

          .row {
            align-items: center;
          }
        }
      }

      .row {
        align-items: center;
        margin: 0;
      }

      img {
        width: 100%;
      }

      &__title {
        font-weight: bold;
      }
    }

  }

  .block-success-payment-total {
    margin: 0;

    &__item {
      border-left: 1px solid #eee;
      border-right: 1px solid #eee;
      background: #fff;
      color: #000;

      > .row {

        padding: 10px 0;

        &:not(:last-child) {
          border-bottom: 1px solid #eee;
        }

        &:last-child {
          background: #eee;
        }

      }
    }
  }

  .uppercase {
    text-transform: uppercase;
  }

  .block-success-payment-confirmation {
    &__item {
      background: #fff;
      color: #000;
    }

    margin-bottom: 30px;

    &-email {
      padding: 15px 0;
    }

  }

  .block-success-payment-products-resume {

    margin-bottom: 30px;

    .block-success-payment-resume {
      &__item {

        border: 1px solid #eee;
        background: #f8f8f8;
        color: #000;
        padding: 1rem .5rem 0;

        label {
          color: #000 !important;
        }

        p {
          margin-bottom: 10px;
          margin-top: 0;
        }
      }
    }
  }

  .block-success-payment-information {
    margin-top: 15px;
    color: #000;

    &__item {
      border: 1px solid #eee;
      background: #eee;
      padding: 10px 40px;
    }

  }

}

.template-panier {

  &.template-panier-2, &.template-panier-3, &.template-panier-4 {
    #paniercommande .ajout_panier a {
      vertical-align: middle;
      line-height: 30px;
      height: 42px;
    }
  }

  #amazonpay_cart_login_button {
    display: inline-block;
    vertical-align: middle;
    height: 42px;
    margin-right: 15px;

    img {
      max-height: 42px !important;
      display: block;
    }
  }
}

#amazonpay_addressbook_widget, #readyonly_amazonpay_addressbook_widget {
  min-width: 300px;
  width: 100%;
  max-width: 900px;
  min-height: 228px;
  height: 240px;
  max-height: 400px;
  margin: auto;
}

#amazonpay_wallet_widget, #readyonly_amazonpay_wallet_widget {
  min-width: 300px;
  width: 100%;
  max-width: 900px;
  min-height: 228px;
  height: 240px;
  max-height: 400px;
  margin: 20px auto;
}

@import 'design/template/panier/float_panier/_template_1';
@import 'design/template/panier/float_panier/_template_2';
@import 'design/template/panier/float_panier/_template_3';
@import 'design/template/panier/float_panier/_template_4';
