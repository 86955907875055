@import "../../../../cmonsite-v6/_main_mixin";
@import '../../_mixin_templates';

/* HACK Style panier boutique */
.cmonsite-panier-3 {
    #main-conteneur header{
        position: relative;
    }
    #blocContent {
        position: relative;
    }
}

.template-panier-3 {
    width: 285px;
    float: right;
    top: 0;
    right: 0;
    border: 1px solid;
    position: absolute;
    z-index: 50;

    &.sans-connexion{
        width: 137px;
        .bloc-connexion-panier #panier{
            width:100%;
        }
    }
    .bloc-connexion-panier {
        &::before, &::after {
            content: '';
            display: block;
            clear: both;
        }
    }
    .connexion {
        width: 50%;
        float: left;
        box-sizing: border-box;
        padding-right: 5px;

        $fa-user: "f007";
        $fa-font-size-base: 14px;
        $fa-line-height-base: 1;
        p {
            text-align: center;
            color: #000;
            height: 30px;
            line-height: 30px;
            margin: 5px 0;
            padding: 0 10px;
            border-right: 1px solid;
            font-size: 14px;
            font-weight: bold;
        }

        a {
            color: #000;
            text-decoration: none;
            width: 100%;
            display: block;

            &:before {
                display: inline-block;
                font: normal normal normal #{$fa-font-size-base}/#{$fa-line-height-base} FontAwesome; // shortening font declaration
                font-size: inherit; // can't have font-size inherit on line above, so need to override
                text-rendering: auto; // optimizelegibility throws things off #1094
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                @include char($fa-user);
                margin-right: 10px;
            }
        }
    }
    #panier {
        //display: block !important; //Passer au-dessus du js dans la commande
        border: none;
        bottom: auto;
        width: 50%;
        float: right;
        position: relative;
        border-radius: 0;
        height: 40px;
        padding: 0;
        background: transparent;
        box-shadow: none;
        right: 0;
        font-family: inherit;
    }
    #paniercontent{
        min-width: 500px;
    }
    &.connexion-full {
        width: 137px;
        .bloc-connexion-panier .connexion {
            width: 100%;
            padding-right: 0;
            float: none;
            p {
                border-right: 0;
            }
        }
    }

}
@media #{$medium-and-down} {
    .template-panier-3 {
        #paniercontent{
            left: auto;
            right: 0;
            max-width: 100%;
            padding-top:0;
            min-width: 300px;
        }
    }
}
@media #{$small-and-down} {
    .template-panier-3 {
        width: 100%;
        max-width:300px;
        top: auto;
        right: auto;
        left: auto;
        border: 1px solid;
        margin: 10px auto 0;
        position:static;
        float:none;
        height:auto;
        clear:both;
        min-width: 0;
        #headercontent{
            padding-bottom:20px;
        }
        &.sans-connexion{
            width: 100%;
        }
        .connexion {
            /*width: 100%;*/
            padding:0;
            p{
                border:none;
            }
        }
        #header-panier{
            text-align: center;
            position:relative;
        }
        .count-live{
            top:auto;
            margin-left:10px;
            left:115px;
        }
        #panier{
            position:static;
            /*width:100%;*/
        }
        #paniercontent{
            min-width:0;
            left:5%;
            right:5%;
            width:90%;
            max-width: 100%;
            box-sizing: border-box;
            z-index: 9999;
            top:100%;
        }
        .paniertoggle{
            float:none;
            position:absolute;
            top:0;
            right:0;
            width:100%;
            height:100%;
            .fa{
                position:absolute;
                right: 7px;
                top:47%;
                margin-top: -8px;
            }
        }
    }
}