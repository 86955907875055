/*
    Document   : _main_mixin
    Created on : 9 août 2013, 11:44:12
    Author     : PC1
    Description: Fonction SASS Réutilisable de Cmonsite
*/

// Default 990px grid sur 10 colonne
// -------------------------
$gridColumns: 12 !default;
$gridColumnWidth: 60px !default;
$gridGutterWidth: 20px !default;
$gridRowWidth: ($gridColumns * $gridColumnWidth) + ($gridGutterWidth * ($gridColumns - 1)) !default;

// Fluid grid
// -------------------------
$fluidGridColumnWidth: percentage($gridColumnWidth/$gridRowWidth) !default;
$fluidGridGutterWidth: percentage($gridGutterWidth/$gridRowWidth) !default;
$baseLineHeight: 20px !default;
$inputHeight: $baseLineHeight + 10px; // base line-height + 8px vertical padding + 2px top/bottom border


// Mixins clearfix bootstrap

@mixin clearfix {
    *zoom: 1;
    &:before,
    &:after {
        display: table;
        content: "";
        // Fixes Opera/contenteditable bug:
        // http://nicolasgallagher.com/micro-clearfix-hack/#comment-36952
        line-height: 0;
    }
    &:after {
        clear: both;
    }
}
// Block level inputs
@mixin input-block-level {
    display: block;
    width: 100%;
    min-height: $inputHeight; // Make inputs at least the height of their button counterpart (base line-height + padding + border)
    box-sizing: border-box; // Makes inputs behave like true block-level elements
}

// IE7 inline-block
// ----------------
@mixin crossbrowser-inline-block() {
    display: -moz-inline-stack;
    *display: inline; /* IE7 inline-block hack */
    *zoom: 1;
}

// Opacity
@mixin opacity($opacity) {
    opacity: $opacity / 100;
    filter: alpha(opacity=$opacity);
}
// Transitions
@mixin transition($transition...) {
    -webkit-transition: $transition;
    -moz-transition: $transition;
    -o-transition: $transition;
    transition: $transition;
}
@mixin transition-delay($transition-delay) {
    -webkit-transition-delay: $transition-delay;
    -moz-transition-delay: $transition-delay;
    -o-transition-delay: $transition-delay;
    transition-delay: $transition-delay;
}
@mixin transition-duration($transition-duration) {
    -webkit-transition-duration: $transition-duration;
    -moz-transition-duration: $transition-duration;
    -o-transition-duration: $transition-duration;
    transition-duration: $transition-duration;
}
/*
    Example usage:
    @include animation(10s, 5s, changecolour)
*/
@mixin animation ($delay, $duration, $animation, $count : 1) {
    -webkit-animation-delay: $delay;
    -webkit-animation-duration: $duration;
    -webkit-animation-name: $animation;
    -webkit-animation-fill-mode: forwards; /* this prevents the animation from restarting! */
    -webkit-animation-iteration-count:$count;
    
    -moz-animation-delay: $delay;
    -moz-animation-duration: $duration;
    -moz-animation-name: $animation;
    -moz-animation-fill-mode: forwards; /* this prevents the animation from restarting! */
    -moz-animation-iteration-count:$count;
    
    -o-animation-delay: $delay;
    -o-animation-duration: $duration;
    -o-animation-name: $animation;
    -o-animation-fill-mode: forwards; /* this prevents the animation from restarting! */
    -o-animation-iteration-count:$count;
    
    animation-delay: $delay;
    animation-duration: $duration;
    animation-name: $animation;
    animation-fill-mode: forwards; /* this prevents the animation from restarting! */
    animation-iteration-count:$count;
}
// keyframes mixin
@mixin keyframes($name) {
    @-webkit-keyframes #{$name} {
        @content;
    }
    @-moz-keyframes #{$name} {
        @content;
    }
    @-ms-keyframes #{$name} {
        @content;
    }
    @keyframes #{$name} {
        @content;
    }
}
// Mixin Bootstrap pour générer une grille
@mixin grid-core($gridColumnWidth, $gridGutterWidth) {
    
    .row {
        margin-left: $gridGutterWidth * -1;
        @include clearfix();
    }
    
    
    [class*="span"] {
        float: left;
        min-height: 1px; // prevent collapsing columns
        margin-left: $gridGutterWidth;
    }
    
    // generate .spanX
    @include grid-core-span-x($gridColumns, $gridColumnWidth, $gridGutterWidth);
    // generate .offsetX
    @include grid-core-offset-x($gridColumns, $gridColumnWidth, $gridGutterWidth);
    
}
@mixin grid-core-span-x($gridColumns, $gridColumnWidth, $gridGutterWidth) {
    @for $i from 1 through $gridColumns {
        .span#{$i} { @include grid-core-span($i, $gridColumnWidth, $gridGutterWidth); }
    }
}

@mixin grid-core-offset-x($gridColumns, $gridColumnWidth, $gridGutterWidth) {
    @for $i from 1 through $gridColumns {
        .offset#{$i} { @include grid-core-offset($i, $gridColumnWidth, $gridGutterWidth); }
    }
}

@mixin grid-core-span($columns, $gridColumnWidth, $gridGutterWidth) {
    width: ($gridColumnWidth * $columns) + ($gridGutterWidth * ($columns - 1));
}

@mixin grid-core-offset($columns, $gridColumnWidth, $gridGutterWidth) {
    margin-left: ($gridColumnWidth * $columns) + ($gridGutterWidth * ($columns - 1));
}

@mixin grid-fluid($fluidGridColumnWidth, $fluidGridGutterWidth) {
    .row-fluid {
        
        @include clearfix();
        [class*="span"] {
            @include input-block-level();
            float: left;
            margin-left: $fluidGridGutterWidth;
            *margin-left: $fluidGridGutterWidth - (.5 / $gridRowWidth * 100px * 1%);
        }
        [class*="span"]:first-child {
            margin-left: 0;
        }
        
        // Space grid-sized controls properly if multiple per line
        .controls-row [class*="span"] + [class*="span"] {
            margin-left: $fluidGridGutterWidth;
        }
        
        // generate .spanX and .offsetX
        @include grid-fluid-span-x($gridColumns, $fluidGridColumnWidth, $fluidGridGutterWidth);
        @include grid-fluid-offset-x($gridColumns, $fluidGridColumnWidth, $fluidGridGutterWidth);
    }
}



@mixin grid-fluid-span-x($gridColumns, $fluidGridColumnWidth, $fluidGridGutterWidth) {
    @for $i from 1 through $gridColumns {
        .span#{$i} { @include grid-fluid-span($i, $fluidGridColumnWidth, $fluidGridGutterWidth); }
    }
}

@mixin grid-fluid-offset-x($gridColumns, $fluidGridColumnWidth, $fluidGridGutterWidth) {
    @for $i from 1 through $gridColumns {
        .offset#{$i} { @include grid-fluid-offset($i, $fluidGridColumnWidth, $fluidGridGutterWidth); }
        .offset#{$i}:first-child { @include grid-fluid-offset-first-child($i, $fluidGridColumnWidth, $fluidGridGutterWidth); }
    }
}

@mixin grid-fluid-span($columns, $fluidGridColumnWidth, $fluidGridGutterWidth) {
    width: ($fluidGridColumnWidth * $columns) + ($fluidGridGutterWidth * ($columns - 1));
    *width: ($fluidGridColumnWidth * $columns) + ($fluidGridGutterWidth * ($columns - 1)) - (.5 / $gridRowWidth * 100px * 1%);
}

@mixin grid-fluid-offset($columns, $fluidGridColumnWidth, $fluidGridGutterWidth) {
    margin-left: ($fluidGridColumnWidth * $columns) + ($fluidGridGutterWidth * ($columns - 1)) + ($fluidGridGutterWidth * 2);
    *margin-left: ($fluidGridColumnWidth * $columns) + ($fluidGridGutterWidth * ($columns - 1)) - (.5 / $gridRowWidth * 100px * 1%) + ($fluidGridGutterWidth * 2) - (.5 / $gridRowWidth * 100px * 1%);
}

@mixin grid-fluid-offset-first-child($columns, $fluidGridColumnWidth, $fluidGridGutterWidth) {
    margin-left: ($fluidGridColumnWidth * $columns) + ($fluidGridGutterWidth * ($columns - 1)) + ($fluidGridGutterWidth);
    *margin-left: ($fluidGridColumnWidth * $columns) + ($fluidGridGutterWidth * ($columns - 1)) - (.5 / $gridRowWidth * 100px * 1%) + $fluidGridGutterWidth - (.5 / $gridRowWidth * 100px * 1%);
}


@mixin char($character-code) {
    // Escaping in Sass is a bit of a mess?
    // https://github.com/sass/sass/issues/659
    @if function-exists(random) {
        @if function-exists(selector-nest) {
            // Sass 3.4
            content: #{'\"\\' + $character-code + "\""};
        } @else {
            // LibSass
            // Escaping:
            // https://github.com/sass/libsass/issues/1271
            //
            // Outputs a unicode entity instead of a code such as "\f102":
            // https://github.com/sass/libsass/issues/1231
            content: "#{'\"\\' + $character-code + "\""}";
        }
    } @else {
        // Sass 3.3
        content: str-slice("\x", 1, 1) + $character-code;
    }
}